<template>
  <div class="payment">
        <div class="payment_chent">
          <div class="payment_lin" v-for="item,index in GoodsListData" :key="index">
             <div style="width: 40%;"><img :src="item.img_url" style="width: 9rem;height: 7rem;border-radius: 5px;"></div>
             <div style="width: 60%;" class="payment_ring">
                 <div class="payment_test">{{item.name}}</div>
                 <div class="payment_ke">选择款式：直播课</div>
                 <div class="payment_neir">
                    <div>￥{{item.price}}</div>
                    <div>×1</div>
                 </div>
                 <div class="payment_dd">共一件商品，合计￥{{item.price}}</div>
             </div>
          </div>
        </div>
        <div class="payment_di">
           <div class="payment_ne">合计 <span>￥{{price}}</span></div>
           <div @click="paymentclick()" class="payment_dun">去支付<img src="../assets/Vector.svg"></div>
        </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      GoodsListData:[],
      price:"",
    };
  },
  created() {
    this.getGoodsList();
  },
  activated() {},
  watch: {},

  methods: {
    getGoodsList(){
      this.$api.user.getGoodsList().then((res)=>{
          if(res.code == 1){
              this.GoodsListData = res.data;
              this.price = res.data[0].price;
          }
      })
    },

    paymentclick(){
        var url = 'https://service.xiaolu178.cn/index.php/applet/h5Pay';
        var data = {
            // 商品名称: '商品名称',
            // 商品价格: '100',
            // 其他支付参数
        };

        // 发起支付请求
        this.wxPayClin(url, data);
    },
    wxPayClin(url, data) {
        // 调用微信支付接口
        // 这里可以使用Ajax请求或其他方式调用微信支付接口
        // 这里仅作示例，假设使用Ajax请求
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            // if (xhr.readyState === 4 && xhr.status === 200) {
                var response = JSON.parse(xhr.responseText);
                console.log(response);
                if (response.code === 1) {
                    // 支付成功后的处理逻辑
                    window.location.href = response.data.url;
                } else {
                    // 支付失败后的处理逻辑
                    alert('支付失败！');
                }
            // } else {
            //     // 请求失败的处理逻辑
            //     alert('请求失败！');
            // }
        };
        xhr.send(JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.payment_dun{
    width: 120px;
    height: 45px;
    border-radius: 10px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(275deg, #3B53E2 0%, #7596FE 100%);
}
.payment_ne{
    color: black;
    font-size: 17px;
    margin-left: 20px;
}
.payment_di{
    width: 100%;
    background: #fff;
    height: 70px;
    position: fixed;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payment_dd{
    font-size: 11px;
    text-align: right;
    margin-top: 2px;
}
.payment_neir{
    height: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.payment_ke{
    color: rgba(0, 0, 0, 0.50);
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.payment_ring{
    margin-left: 10px;
}
.payment_test{
    color: rgba(0, 0, 0, 0.85);
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
}
.payment_lin{
    height: 8rem;
    background: white;
    border-radius: 5px;
    padding: 15px;
    display: flex;
}
.payment{
   background: #F1F2F3;
   width: 100%;
   height: 100vh;

}
.payment_chent{
   padding-top: 15px;
   width: 90%;
   height: 100%;    
   margin: 0 auto;
}
*,
body {
}

@import "./css/h5.scss";
</style>
